<script setup lang="ts">
import {FoundHit} from "~/components/AppSearch.vue";
import {PropType} from "vue";

const props = defineProps({
  icon: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  suffixIcon: {
    type: String,
    required: false,
  },
  item: {
    type: Object as PropType<FoundHit>,
    required: true,
  },
})

const emit = defineEmits(['itemSelected'])
const onItemClicked = () => {
  emit('itemSelected', props.item)
}
</script>

<template>
  <div class="search-result-item flex items-center gap-x-3" @focus="onItemClicked" @click="onItemClicked">
    <div class="search-result-item--icon surface-100 text-gray-500 rounded-full flex items-center justify-center">
      <i :class="icon" />
    </div>
    <div class="flex-1 flex items-center gap-2">
      <slot name="title">
        {{ title }}
      </slot>

      <div v-if="suffixIcon" class="text-gray-400 text-sm">
        <i :class="suffixIcon" class="text-sm" />
      </div>
    </div>

    <div class="pr-2 search-result-item--chevron">
      <i class="pi pi-chevron-right" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.search-result-item {
  @apply p-1.5 rounded-lg text-black;
  &--icon {
    padding: 0.625rem;
    transition: all 0.3s ease-in-out;
  }

  &--chevron {
    transition: all 0.2s ease-in-out;
    opacity: 0;
  }

  &:hover, &:focus {
    cursor: pointer;
    background-color: var(--surface-50);
    transition: all 0.3s ease-in-out;

    .search-result-item--chevron {
      opacity: 1;
    }

    .search-result-item--icon {
      background-color: var(--primary-color) !important;
      color: var(--surface-100) !important;
    }
  }
}
</style>
