import { DynamicDialogInstance, DynamicDialogOptions } from 'primevue/dynamicdialogoptions';
import { ToastServiceMethods } from 'primevue/toastservice';
import { DocumentType } from '~/types/Document';
import InvoiceFormModalSuspense from '~/components/Invoice/InvoiceFormModalSuspense.vue';
import CreateCustomerForm from "~/components/Customer/CreateCustomerForm.vue";
import {usePermissions} from "~/composables/usePermissions";
import {AppPermissions} from "~/types/Permissions";
import AssignPartnerDialog from "~/components/Customer/AssignPartnerDialog.vue";

export default class CustomerService {
  private readonly dialog: ReturnType<typeof useModal>;

  private readonly toast: ToastServiceMethods;
  private readonly confirmation: ReturnType<typeof useConfirmation>;

  public constructor(
    dialog: ReturnType<typeof useModal>,
    toast: ToastServiceMethods,
    confirmation: ReturnType<typeof useConfirmation>,
  ) {
    this.dialog = dialog;
    this.toast = toast;
    this.confirmation = confirmation;
  }

  getCustomersSmall() {
    return fetch('/data/customers-small.json')
      .then((res) => res.json())
      .then((d) => d.data);
  }

  getCustomersMedium() {
    return fetch('/data/customers-medium.json')
      .then((res) => res.json())
      .then((d) => d.data);
  }

  getCustomersLarge() {
    return fetch('/data/customers-large.json')
      .then((res) => res.json())
      .then((d) => d.data);
  }

  getCustomersXLarge() {
    return fetch('/data/customers-xlarge.json')
      .then((res) => res.json())
      .then((d) => d.data);
  }

  getCustomers(params) {
    const queryParams = Object.keys(params)
      .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
      .join('&');
    return fetch(`https://www.primefaces.org/data/customers?${queryParams}`).then((res) => res.json());
  }

  public openNewInvoice(customerId: string) {
    this.dialog.open(InvoiceFormModalSuspense, {
      props: {
        header: 'Rechnung erstellen',
        modal: true,
        style: {
          width: '70vw',
        },
      },
      data: {
        customerId,
        documentType: DocumentType.Invoice,
      },
      onClose: (data: any) => {
        if (data.data && data.data.id) {
          navigateTo(`/invoices/${data.data.id}`);
        }
      },
    });
  }

  public customerName(customer: any) {
    if (!customer) return '';

    if (customer?.label) return customer.label;

    if (customer.companyName) {
      return customer.companyName;
    }

    return `${customer.firstName} ${customer.lastName}`;
  }

  public customerIcon(customer: any) {
    if (!customer) return '';

    if (customer.companyName) {
      return 'pi pi-building';
    }

    return 'pi pi-user';
  }

  public openNewCustomer() {
    this.dialog.open(CreateCustomerForm, {
      props: {
        header: 'Kunden anlegen',
        modal: true,
        style: {
          width: '70vw',
        },
      },
      onClose: (data: any) => {
        if (data.data && data.data.id) {
          navigateTo(`/customers/${data.data.id}`);
        }
      },
    });
  }

  public async assignPartner(customerId: string) {
    if (!usePermissions().can(AppPermissions.CustomerWrite)) {
      return;
    }

    this.dialog.open(AssignPartnerDialog, {
      props: {
        header: 'Partner zuweisen',
      },
      data: {
        customerId,
      },
      onClose: (data: any) => {
        if (data.data && data.data.id) {
          navigateTo(`/customers/${data.data.id}`);
        }
      },
    });
  }

  public async archiveCustomerConfirm(customerId: string) {
    if (!usePermissions().can(AppPermissions.CustomerWrite)) {
      return;
    }

    const result = await this.confirmation.waitingConfirm({
      header: 'Kunde archivieren',
      message: `Soll der Kunde wirklich archiviert werden? Anschließend wird der Kunde in der Kundenübersicht und Auswahllisten ausgeblendet..`,
      acceptLabel: 'Kunde archivieren',
      acceptClass: 'p-button-danger',
      acceptIcon: 'pi pi-eye-slash',
    });

    if (result) {
      const loadingDialog = this.dialog.loading('Archiviere Kunden...');
      try {
        await this.archiveCustomer(customerId);
        await refreshNuxtData(['customer-details-' + customerId, 'activities']);
        this.toast.add({
          summary: 'Der Kunde wurde erfolgreich archiviert.',
          severity: 'success',
          life: 3500,
        })
      } catch (e) {
        this.toast.add({
          summary: 'Der Kunde konnte nicht archiviert werden.',
          severity: 'error',
          life: 3500,
        });
      } finally {
        loadingDialog.close();
      }
    }
  }

  public archiveCustomer(customerId: string) {
    return $fetch('/api/v1/customers/' + customerId + '/archive', {
      method: 'PUT',
    });
  }

  public async deleteCustomerConfirm(customerId: string) {
    if (!usePermissions().can(AppPermissions.CustomerWrite)) {
      return;
    }

    const result = await this.confirmation.waitingConfirm({
      header: 'Kunde löschen',
      message: `Soll der Kunde wirklich gelöscht werden? Anschließend wird der Kunde unwiderruflich gelöscht.`,
      acceptLabel: 'Kunde löschen',
      acceptClass: 'p-button-danger',
      acceptIcon: 'pi pi-trash',
    });

    if (result) {
      const loadingDialog = this.dialog.loading('Lösche Kunden...');
      try {
        await this.deleteCustomer(customerId);
        await refreshNuxtData(['customer-details-' + customerId, 'activities']);
        this.toast.add({
          detail: 'Der Kunde wurde erfolgreich gelöscht.',
          severity: 'success',
          life: 3500,
        });
        navigateTo('/customers');
      } catch (e) {
        this.toast.add({
          detail: 'Der Kunde konnte nicht gelöscht werden, die Vorbedingungen sind nicht erfüllt.',
          severity: 'error',
          life: 5000,
        });
        return;
      } finally {
        loadingDialog.close();
      }
    }
  }

  public async deleteCustomerConfirmMultiple(customerIds: string[]) {
    if (!usePermissions().can(AppPermissions.CustomerWrite)) {
      return;
    }

    const result = await this.confirmation.waitingConfirm({
      header: 'Kunden löschen',
      message: `Sollen die ${customerIds.length} Kunden wirklich gelöscht werden? Anschließend werden die Kunden unwiderruflich gelöscht.`,
      acceptLabel: 'Kunden löschen',
      acceptClass: 'p-button-danger',
      acceptIcon: 'pi pi-trash',
    });

    if (result) {
      const loadingDialog = this.dialog.loading('Lösche Kunden...');
      try {
        await Promise.all(customerIds.map((customerId) => this.deleteCustomer(customerId)));
        await refreshNuxtData('customers-list-page');
        this.toast.add({
          detail: 'Die Kunden wurden erfolgreich gelöscht.',
          severity: 'success',
          life: 3500,
        })
      } catch (e) {
        this.toast.add({
          detail: 'Die Kunden konnten nicht gelöscht werden, die Vorbedingungen sind nicht erfüllt.',
          severity: 'error',
          life: 5000,
        });
        return;
      } finally {
        loadingDialog.close();
      }
    }
  }

  public async archiveCustomerConfirmMultiple(customerIds: string[]) {
    if (!usePermissions().can(AppPermissions.CustomerWrite)) {
      return;
    }

    const result = await this.confirmation.waitingConfirm({
      header: 'Kunden archivieren',
      message: `Sollen die ${customerIds.length} Kunden wirklich archiviert werden? Anschließend werden die Kunden in der Kundenübersicht und Auswahllisten ausgeblendet.`,
      acceptLabel: 'Kunden archivieren',
      acceptClass: 'p-button-danger',
      acceptIcon: 'pi pi-eye-slash',
    });

    if (result) {
      const loadingDialog = this.dialog.loading('Archiviere Kunden...');
      try {
        await Promise.all(customerIds.map((customerId) => this.archiveCustomer(customerId)));
        await refreshNuxtData('customers-list-page');
        this.toast.add({
          detail: 'Die Kunden wurden erfolgreich archiviert.',
          severity: 'success',
          life: 3500,
        })
      } catch (e) {
        this.toast.add({
          detail: 'Die Kunden konnten nicht archiviert werden, die Vorbedingungen sind nicht erfüllt.',
          severity: 'error',
          life: 5000,
        });
        return;
      } finally {
        loadingDialog.close();
      }
    }
  }

  public deleteCustomer(customerId: string) {
    return $fetch('/api/v1/customers/' + customerId, {
      method: 'DELETE',
    });
  }
}
