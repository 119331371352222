import { DynamicDialogInstance, DynamicDialogOptions } from 'primevue/dynamicdialogoptions';
import { ToastServiceMethods } from 'primevue/toastservice';
import CreateSubscriptionBaseForm from '~/components/Subscription/CreateSubscriptionBaseForm.vue';
import AppLoadingDialogContent from '~/components/AppLoadingDialogContent.vue';
import SubscriptionEstimatedInvoicePreviewSuspense from '~/components/Subscription/SubscriptionEstimatedInvoicePreviewSuspense.vue';
import SubscriptionActivateTrial from '~/components/Subscription/SubscriptionActivateTrial.vue';
import SubscriptionExtendTrial from '~/components/Subscription/SubscriptionExtendTrial.vue';
import {ReturnType} from "birpc";
import useModal from "~/composables/useModal";
import SubscriptionResumeDialog from "~/components/Subscription/SubscriptionResumeDialog.vue";
import SubscriptionCancelDialog from "~/components/Subscription/SubscriptionCancelDialog.vue";

export default class SubscriptionService {
  private readonly dialog: ReturnType<typeof useModal>;

  private readonly toast: ToastServiceMethods;

  public constructor(
    dialog: ReturnType<typeof useModal>,
    toast: ToastServiceMethods
  ) {
    this.dialog = dialog;
    this.toast = toast;
  }

  public openNewSubscription(customerId: string | undefined = undefined) {
    if (!useRoute().query.old) {
      navigateTo('/subscriptions/new' + (customerId ? `?customerId=${customerId}` : ''));
      return;
    }
    this.dialog.open(CreateSubscriptionBaseForm, {
      props: {
        modal: true,
        header: 'Abonnement erstellen',
        draggable: false,
      },
      data: {
        customerId,
      },
      // @ts-ignore
      onClose: (data: any) => {
        if (data.data && data.data.id) {
          navigateTo(`/subscriptions/${data.data.id}`);
        }
      },
    });
  }

  public async activateSubscription(subscriptionId: string, trialDays?: number) {
    const loadingDialog = this.dialog.open(AppLoadingDialogContent, {
      props: {
        modal: true,
        showHeader: false,
        draggable: false,
        closable: false,
      },
      data: {
        message: 'Das Abonnement wird aktiviert...',
      },
    });

    try {
      const response = await $fetch.raw(`/api/v1/subscriptions/${subscriptionId}/activate`, {
        method: 'PUT',
        body: {
          trialDays,
        },
      });
      if (response.status !== 200) {
        throw new Error('Invalid status code');
      }

      this.toast.add({
        severity: 'success',
        summary: 'Erfolgreich',
        detail: 'Das Abonnement wurde aktiviert.',
        life: 3500,
      });

      return response._data;
    } catch (e) {
      this.toast.add({
        severity: 'error',
        summary: 'Fehler',
        detail: 'Das Abonnement konnte nicht aktiviert werden. Bitte versuche es später erneut.',
        life: 3500,
      });

      throw e;
    } finally {
      loadingDialog.close();
    }
  }

  public async previewEstimatedInvoice(subscriptionId: string) {
    const pdfDialog = this.dialog.open(SubscriptionEstimatedInvoicePreviewSuspense, {
      props: {
        modal: true,
        header: 'Nächste Rechnungsvorschau',
        closable: true,
        showHeader: true,
        style: {
          width: '90%',
          height: '90%',
          maxWidth: '90%',
          maxHeight: '90%',
        },
      },
      data: {
        subscriptionId,
      },
    });
  }

  async activateSubscriptionWithTrial(id: string, onSuccess?: Function) {
    const isClosed = ref(false);
    const dialog = this.dialog.open(SubscriptionActivateTrial, {
      props: {
        modal: true,
        header: 'Testzeitraum starten',
        closable: true,
        showHeader: true,
      },
      data: {
        subscriptionId: id,
      },
      onClose({ data }) {
        if (data && data.id) {
          onSuccess && onSuccess(data);
        }

        isClosed.value = true;
      },
    });

    // wait until closed
    await new Promise((resolve) => {
      const interval = setInterval(() => {
        if (isClosed.value) {
          clearInterval(interval);
          resolve(null);
        }
      }, 100);
    });
  }

  async openExtendTrialDialog(subscriptionId: string, hasNextBillingDate: boolean, onSuccess?: Function) {
    const dialog = this.dialog.open(SubscriptionExtendTrial, {
      props: {
        modal: true,
        header: 'Testzeitraum verlängern',
        closable: true,
        showHeader: true,
      },
      data: {
        subscriptionId,
        hasNextBillingDate,
      },
      onClose({ data }) {
        if (data && data.id) {
          onSuccess && onSuccess(data);
        }
      },
    });
  }

  async extendTrial(subscriptionId: any, trialDays: number | undefined) {
    try {
      const response = await $fetch.raw(`/api/v1/subscriptions/${subscriptionId}/extend-trial`, {
        method: 'PUT',
        body: {
          trialDays,
        },
      });
      if (response.status !== 200) {
        throw new Error('Invalid status code');
      }

      this.toast.add({
        severity: 'success',
        summary: 'Erfolgreich',
        detail: 'Der Testzeitraum wurde verlängert.',
        life: 3500,
      });

      return response._data;
    } catch (e) {
      this.toast.add({
        severity: 'error',
        summary: 'Fehler',
        detail: 'Der Testzeitraum konnte nicht verlängert werden. Bitte versuche es später erneut.',
        life: 3500,
      });

      throw e;
    }
  }

  /**
   * With loading dialog
   *
   * @param subscriptionId
   */
  async revokeTrial(subscriptionId: any) {
    const loadingDialog = this.dialog.open(AppLoadingDialogContent, {
      props: {
        modal: true,
        showHeader: false,
        draggable: false,
        closable: false,
      },
      data: {
        message: 'Der Testzeitraum wird beendet...',
      },
    });

    try {
      const response = await $fetch.raw(`/api/v1/subscriptions/${subscriptionId}/revoke-trial`, {
        method: 'PUT',
      });
      if (response.status !== 200) {
        throw new Error('Invalid status code');
      }

      this.toast.add({
        severity: 'success',
        summary: 'Erfolgreich',
        detail: 'Der Testzeitraum wurde beendet.',
        life: 3500,
      });

      return response._data;
    } catch (e) {
      this.toast.add({
        severity: 'error',
        summary: 'Fehler',
        detail: 'Der Testzeitraum konnte nicht beendet werden. Bitte versuche es später erneut.',
        life: 3500,
      });

      throw e;
    } finally {
      loadingDialog.close();
    }
  }

  pause(id: string) {
    const modal = this.dialog.loading('Das Abonnement wird pausiert...');

    return $fetch(`/api/v1/subscriptions/${id}/pause`, {
      method: 'PUT',
    }).then(() => {
      modal.close();
      this.toast.add({
        severity: 'success',
        summary: 'Erfolgreich',
        detail: 'Das Abonnement wurde pausiert.',
        life: 3500,
      });
    }).catch(() => {
      modal.close();
      this.toast.add({
        severity: 'error',
        summary: 'Fehler',
        detail: 'Das Abonnement konnte nicht pausiert werden. Bitte versuche es später erneut.',
        life: 3500,
      });
    });
  }

  async resume(id: string) {
    const result = ref(undefined);

    this.dialog.open(SubscriptionResumeDialog, {
      props: {
        modal: true,
        header: 'Abonnement fortsetzen',
      },
      data: {
        subscriptionId: id,
      },
      onClose: (data) => {
        if (data.data) {
          result.value = data.data;
        } else {
          result.value = null;
        }
      }
    })

    // wait until result is not undefined
    await new Promise((resolve) => {
      const interval = setInterval(() => {
        if (result.value !== undefined) {
          clearInterval(interval);
          resolve(null);
        }
      }, 100);
    });

    return result.value === null ? undefined : result.value;
  }

  async deleteSubscription(id: string) {
    return $fetch(`/api/v1/subscriptions/${id}`, {
      method: 'DELETE',
      body: {}
    });
  }

  async cancel(id: any, data: {
    nextPossibleDate: string|Date,
    subscriptionNumber: string,
    customer: {
      id: string
      firstName?: string
      lastName?: string
      companyName?: string
    }
  }): Promise<{subscription: any} | undefined> {
    const result = ref<any>(undefined);

    this.dialog.open(SubscriptionCancelDialog, {
      props: {
        header: 'Abonnement kündigen',
      },
      data: {
        subscriptionId: id,
        nextPossibleDate: data.nextPossibleDate,
        subscriptionNumber: data.subscriptionNumber,
        customer: data.customer,
      },
      onClose: (data) => {
        if (data?.data) {
          result.value = data.data;
        } else {
          result.value = null;
        }
      }
    })

    // wait until result is not undefined
    await new Promise((resolve) => {
      const interval = setInterval(() => {
        if (result.value !== undefined) {
          clearInterval(interval);
          resolve(null);
        }
      }, 100);
    });

    return result.value === null ? undefined : { subscription: result.value };
  }
}
