<script setup lang="ts">
const props = defineProps({
  date: {
    required: true,
    validator(value: unknown): boolean {
      return value instanceof Date || value === null || value === undefined || value === '' || typeof value === 'string'
    }
  },
  format: {
    type: String,
    required: false,
    default: 'dd.mm.yy'
  },
  showTime: {
    type: Boolean,
    required: false,
    default: false
  },
  asDays: {
    type: Boolean,
    required: false,
    default: false
  },
  superShort: {
    type: Boolean,
    required: false,
    default: false
  },
  showSeconds: {
    type: Boolean,
    required: false,
    default: true
  }
})

const asDaysFlag = computed(() => {
  // if > 90 days diff return
  if (props.asDays) {
    const date = new Date(props.date)
    const today = new Date()
    const diffTime = Math.abs(today.getTime() - date.getTime())
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
    return diffDays < 90
  }

  return false
})

const formattedDays = computed(() => {
  if (props.asDays) {
    const date = new Date(props.date)
    const today = new Date()
    const diffTime = Math.abs(today.getTime() - date.getTime())
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
    const isToday = date.toLocaleDateString() === today.toLocaleDateString()
    if (isToday) {
      return 'heute'
    }
    const suffix = date < today ? 'vor ' : 'in '
    return suffix + diffDays + (diffDays === 1 ? ' Tag' : ' Tagen')
  }

  return ''
})

const formattedDate = computed(() => {
  if (!props.date) {
    return ''
  }

  if (props.date instanceof Date) {
    return props.date.toLocaleDateString('de-DE', {
      year: props.superShort ? '2-digit' : 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: props.showTime ? '2-digit' : undefined,
      minute: props.showTime ? '2-digit' : undefined,
    })
  }

  return new Date(props.date).toLocaleDateString('de-DE', {
    year: props.superShort ? '2-digit' : 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: props.showTime ? '2-digit' : undefined,
    minute: props.showTime ? '2-digit' : undefined,
    second: props.showTime ? (props.showSeconds ? '2-digit' : undefined) : undefined,
  })
})
</script>

<template>
  <span v-if="!asDaysFlag">{{ formattedDate }}</span>
  <span v-else v-tooltip:top="formattedDate" class="cursor-pointer">{{ formattedDays }}</span>
</template>

<style scoped lang="scss">

</style>
