import { defineStore } from 'pinia';

export const useSearch = defineStore('search', {
  state: () => ({
    lastSearchResult: [],
  }),
  actions: {
    async setLastSearchResult(searchResult: any) {
      this.lastSearchResult = searchResult;
    },
  },
  getters: {
    getLastSearchResult: (state) => () => {
      return state.lastSearchResult;
    },
  },
});
